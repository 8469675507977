import HeaderCom from '@/components/HeaderCom.vue';
import FooterCom from '@/components/footerCom.vue';
import ApplyCom from '@/components/applyCom.vue';
import ProductNavbar from './components/productNavbar.vue';
import NumberCom from './components/numberCom.vue';
import BolgListCom from './components/BolgListCom.vue';
import DetectionTool from './components/DetectionTool.vue';
import BannarCom from './components/bannarCom.vue';
import { ref, onMounted } from 'vue';
import { wachtPageAni } from '@/uitl/currencyFunc.js';
export default {
  components: {
    HeaderCom,
    ApplyCom,
    NumberCom,
    ProductNavbar,
    BolgListCom,
    DetectionTool,
    BannarCom,
    FooterCom
  },
  name: 'App',
  setup() {
    const DetectionTool = ref(null);
    const NumberCom = ref(null);
    const ProductNavbar = ref(null);
    const BolgListCom = ref(null);
    const ApplyComRef = ref(null);
    onMounted(() => {
      wachtPageAni([DetectionTool, NumberCom, ProductNavbar, BolgListCom, ApplyComRef]);
    });
    return {
      DetectionTool,
      NumberCom,
      ProductNavbar,
      BolgListCom,
      ApplyComRef
    };
  }
};